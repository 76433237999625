import Router from "next/router"
import NProgress from "nprogress"
import { AppProvider } from "@/lib/contexts/appContext"
import "../styles/app.css"

NProgress.configure({
  showSpinner: false // Set to false if you want to disable the spinner
})

Router.events.on("routeChangeStart", () => NProgress.start())
Router.events.on("routeChangeComplete", () => NProgress.done())
Router.events.on("routeChangeError", () => NProgress.done())

export default function App({ Component, pageProps }) {
  if (pageProps?.pathData) {
    return (
      <AppProvider pathData={pageProps.pathData} context={pageProps.context}>
        <Component {...pageProps} />
      </AppProvider>
    )
  }

  return <Component {...pageProps} />
}
